/**
 * @generated SignedSource<<f320f47cd477b16e2b94ab590c3acf04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type webFormQueryUtils_useWebFormContentUsageQuery$variables = {
  usageId: string;
};
export type webFormQueryUtils_useWebFormContentUsageQuery$data = {
  readonly contentUsage: {
    readonly id?: string;
    readonly passPercentage?: number | null;
    readonly module?: {
      readonly isCurriculumModule: boolean;
    } | null;
  } | null;
};
export type webFormQueryUtils_useWebFormContentUsageQuery = {
  variables: webFormQueryUtils_useWebFormContentUsageQuery$variables;
  response: webFormQueryUtils_useWebFormContentUsageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "usageId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "usageId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "passPercentage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isCurriculumModule",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "webFormQueryUtils_useWebFormContentUsageQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "module",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "webFormQueryUtils_useWebFormContentUsageQuery",
    "selections": [
      {
        "alias": "contentUsage",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "module",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88da605c612184eecc709f69c4ea7efa",
    "id": null,
    "metadata": {},
    "name": "webFormQueryUtils_useWebFormContentUsageQuery",
    "operationKind": "query",
    "text": "query webFormQueryUtils_useWebFormContentUsageQuery(\n  $usageId: ID!\n) {\n  contentUsage: node(id: $usageId) {\n    __typename\n    ... on ContentUsage {\n      id\n      passPercentage\n      module {\n        isCurriculumModule\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "20555f8f16776cd7edb1ddcb8b3dc449";

export default node;
