import { GlobalID } from "@/relay/RelayTypes"
import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

/**
 *
 * @param {React.RefObject<HTMLElement | null>} ref - the element to scroll to
 * @param {GlobalID} globalID - the globalID of the entity we are scrolling to, which will be checked against the hash
 */
function useScrollToHash(ref: React.RefObject<HTMLElement | null>, globalID: GlobalID) {
  const location = useLocation()
  useEffect(() => {
    const scroll = () => {
      if (!ref?.current) return
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
    // If there is a hash set, only scroll to that element.
    if (!location.hash) return
    if (location.hash.slice(1) !== globalID) return

    scroll()
  }, [location.hash, globalID, ref])
}

export default useScrollToHash
