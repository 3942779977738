import Relay from "@/relay/relayUtils"
import { usePermissionsFragment$key } from "@utils/hook/__generated__/usePermissionsFragment.graphql"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

function usePermissions(
  entityKey: usePermissionsFragment$key | null | undefined
): Set<string> {
  const entity = useFragment<usePermissionsFragment$key>(
    graphql`
      fragment usePermissionsFragment on Node {
        # We need to query __typename or sometimes the server sometimes won't return
        # viewerPermissions on the node at all
        __typename
        ... on Organization {
          viewerPermissions
        }
        ... on Product {
          viewerPermissions
        }
        ... on Event {
          viewerPermissions
        }
        ... on Comment {
          viewerPermissions
        }
        ... on Feed {
          viewerPermissions
        }
        ... on Post {
          viewerPermissions
        }
        ... on ContentUsage {
          viewerPermissions
        }
        ... on ChatChannel {
          viewerPermissions
        }
        ... on Dashboard {
          viewerPermissions
        }
        ... on AssignmentSubmission {
          viewerPermissions
        }
        ... on PathwayGroup {
          viewerPermissions
        }
      }
    `,
    entityKey ?? null
  )
  if (Relay.isNodeType(entity, "%other")) return new Set()
  return new Set(entity?.viewerPermissions)
}

export default usePermissions
