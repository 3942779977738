/**
 * @generated SignedSource<<c8d5145890f61115655d829249f2b12c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type usePermissionsFragment$data = {
  readonly __typename: "Organization";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "Product";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "Event";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "Comment";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "Feed";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "Post";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "ContentUsage";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "ChatChannel";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "Dashboard";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "AssignmentSubmission";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  readonly __typename: "PathwayGroup";
  readonly viewerPermissions: ReadonlyArray<string>;
  readonly " $fragmentType": "usePermissionsFragment";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "usePermissionsFragment";
};
export type usePermissionsFragment$key = {
  readonly " $data"?: usePermissionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerPermissions",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "usePermissionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Organization",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Product",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Event",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Comment",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Feed",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Post",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "ContentUsage",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "ChatChannel",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "Dashboard",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "AssignmentSubmission",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "PathwayGroup",
      "abstractKey": null
    }
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
})();

(node as any).hash = "e617d71624058dca46072d396ee8fbe9";

export default node;
