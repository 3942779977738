import { GenerationStatus } from "@/content/ai/GenerateContentWithAIProvider"
import ClipboardIcon from "@/core/ui/images/empty-state-illustrations/files.svg"
import WebFormEditorQuestion from "@/web-form/editor/WebFormEditorQuestion"
import WebFormQuestionTypeDropdown from "@/web-form/editor/WebFormQuestionTypeDropdown"
import useGenerateAIQuiz from "@/web-form/utils/useGenerateAIQuiz"
import {
  getFirstWebFormQuestionError,
  useWebFormEditorContext,
  WebFormQuestionInput,
} from "@/web-form/utils/webFormEditorUtils"
import { WebFormQuestionType } from "@/web-form/utils/__generated__/webFormEditorUtils_getFormStateFromRevisionFragment.graphql"
import DragDrop from "@components/drag-drop/DragDrop"
import { LexicalConfigType } from "@components/editor/config/LexicalConfig"
import EditorUtils from "@components/editor/EditorUtils"
import { DiscoButton, DiscoEmptyState } from "@disco-ui"
import DiscoDividerButton from "@disco-ui/button/DiscoDividerButton"
import { useTheme } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { cloneDeep } from "lodash"
import { observer } from "mobx-react-lite"
import { createElement, FC, ReactNode, useEffect, useState } from "react"
import { DropResult } from "react-beautiful-dnd"
import { v4 as uuidv4 } from "uuid"

type Props = {
  renderCustomControls?: (questionIndex: number) => ReactNode
  renderCustomTitle?: (questionIndex: number) => ReactNode
  onCreateQuestion?: (questionIndex: number) => void
  onDuplicateQuestion?: (sourceIndex: number, duplicateIndex: number) => void
  // Allows controlling whether or not a question is deleted
  beforeDeleteQuestion?: (executeDelete: () => void, questionIndex: number) => void
  onDeleteQuestion?: (questionIndex: number) => void
  onReorderQuestion?: (oldIndex: number, newIndex: number) => void
  onQuizGenerationStart?: () => void
  onQuizGenerationStatusChange?: (status: GenerationStatus) => void
  emptyState?: {
    icon?: ReactNode
    title?: string
    subtitle?: string
    ctaText?: string
    component?: FC<any>
  } | null
  allowedQuestionTypes?: WebFormQuestionType[]
  classes?: Partial<ClassNameMap<"dragDropContainer">>
  readOnly?: boolean
  editorConfig?: LexicalConfigType
  showAIGenerateButton?: boolean
}

function WebFormEditor(props: Props) {
  const {
    renderCustomControls,
    renderCustomTitle,
    onCreateQuestion,
    onDuplicateQuestion,
    beforeDeleteQuestion,
    onDeleteQuestion,
    onReorderQuestion,
    emptyState,
    allowedQuestionTypes,
    classes: customClasses,
    readOnly,
    showAIGenerateButton,
    editorConfig,
    onQuizGenerationStatusChange,
    onQuizGenerationStart,
  } = props
  const { form, template } = useWebFormEditorContext()!
  const revision =
    "questions" in form.state
      ? form.state
      : form.state.webFormRevision ?? form.state.content?.webFormRevision
  const [scrollIntoView, setScrollIntoView] = useState<{
    key: string
    position: ScrollLogicalPosition
  } | null>(null)
  const theme = useTheme()

  useGenerateAIQuiz({
    questions: revision?.questions,
    onStart: onQuizGenerationStart,
    onStatusChange: onQuizGenerationStatusChange,
  })

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    let el: HTMLElement | null = null
    if (scrollIntoView?.key) {
      // start polling for the element
      timeout = setInterval(() => {
        el = document.getElementById(scrollIntoView.key)
        if (el) {
          el.scrollIntoView({ block: scrollIntoView.position, behavior: "smooth" })
          // once the element appears, stop polling and clear the key
          setScrollIntoView(null)
          if (timeout) clearInterval(timeout)
        }
      }, 100)
    }
    return () => {
      if (timeout) clearInterval(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollIntoView?.key])

  // Scroll the first question with an error into view
  const firstQuestionError = getScrollToQuestionError()
  useEffect(() => {
    if (!firstQuestionError) return
    setScrollIntoView(firstQuestionError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstQuestionError?.key, setScrollIntoView])

  if (!revision) return null
  const { questions } = revision

  return (
    <>
      {questions.length ? (
        <>
          <DragDrop
            items={questions.map((q) => ({ id: q.uniqueKey }))}
            uniqueKey={"web-form-editor"}
            onDragEnd={handleDragEnd}
            forwardDragHandle
            customClassName={customClasses?.dragDropContainer}
            isDragDisabled={readOnly}
          >
            {(item, _, i, __, dragHandleProps) => (
              <div id={item.id} data-testid={`WebFormEditor.wrapper.${i + 1}`}>
                <WebFormEditorQuestion
                  question={questions.find((q) => q.uniqueKey === item.id)!}
                  questionIndex={i}
                  dragHandleProps={dragHandleProps}
                  onDuplicate={handleDuplicate}
                  onDelete={handleDelete}
                  customControls={renderCustomControls && renderCustomControls(i)}
                  customTitle={renderCustomTitle && renderCustomTitle(i)}
                  editorConfig={editorConfig}
                  showAIGenerateButton={showAIGenerateButton}
                  readonly={readOnly}
                />
                {readOnly ? (
                  <div style={{ height: theme.spacing(3) }} />
                ) : (
                  <WebFormQuestionTypeDropdown
                    testid={`WebFormEditor.type-dropdown.${i + 1}`}
                    onSelect={(type) => handleCreate(i + 1, type)}
                    menuButton={({ onClick }) => (
                      <DiscoDividerButton
                        testid={`WebFormEditor.add-question.${i + 1}`}
                        tooltip={emptyState?.ctaText ?? "Add question"}
                        onClick={onClick}
                        disabled={readOnly}
                      />
                    )}
                    allowedTypes={allowedQuestionTypes}
                  />
                )}
              </div>
            )}
          </DragDrop>
        </>
      ) : (
        getWebFormEditorEmptyState()
      )}
    </>
  )

  function handleCreate(index: number, type: WebFormQuestionType) {
    const question: WebFormQuestionInput = {
      uniqueKey: uuidv4(),
      type,
      richEditorBody: JSON.stringify(EditorUtils.createParagraphs([""])),
      isRequired: template === "quiz",
      options: null,
    }
    if (
      type === "single_select" ||
      type === "multiple_select" ||
      type === "rating" ||
      type === "ranking"
    ) {
      question.options = Array.from({ length: type === "rating" ? 5 : 2 }, () => ({
        id: uuidv4(),
        label: "",
      }))
    }
    questions.splice(index, 0, question)
    if (onCreateQuestion) onCreateQuestion(index)
    // scroll to the newly created question
    setScrollIntoView({ key: question.uniqueKey, position: "center" })
  }

  function handleDuplicate(index: number) {
    const source = questions[index]
    const duplicate = cloneDeep(source)
    duplicate.uniqueKey = uuidv4()
    duplicate.options?.map((o) => (o.id = uuidv4()))
    const { answerKey } = source
    duplicate.answerKey = answerKey ? { ...answerKey, correctIds: [] } : null
    questions.splice(index + 1, 0, duplicate)
    if (onDuplicateQuestion) onDuplicateQuestion(index, index + 1)
    // scroll to the duplicated question
    setScrollIntoView({ key: duplicate.uniqueKey, position: "center" })
  }

  function handleDelete(index: number) {
    if (beforeDeleteQuestion) {
      beforeDeleteQuestion(() => {
        deleteQuestion(index)
      }, index)
      return
    }
    deleteQuestion(index)
  }

  function getWebFormEditorEmptyState() {
    if (emptyState?.component) return createElement(emptyState.component)

    return (
      <DiscoEmptyState
        variant={"compact"}
        testid={"WebFormEditor"}
        icon={emptyState?.icon ?? <ClipboardIcon />}
        title={emptyState?.title ?? ""}
        subtitle={emptyState?.subtitle}
        buttons={
          <WebFormQuestionTypeDropdown
            testid={`WebFormEditor.type-dropdown.0`}
            onSelect={(type) => handleCreate(0, type)}
            menuButton={({ onClick }) => (
              <DiscoButton
                testid={"WebFormEditor.empty-state.add-question"}
                onClick={onClick}
              >
                {emptyState?.ctaText ?? "Add question"}
              </DiscoButton>
            )}
            allowedTypes={allowedQuestionTypes}
          />
        }
      />
    )
  }

  function deleteQuestion(index: number) {
    questions.splice(index, 1)
    if (onDeleteQuestion) onDeleteQuestion(index)
  }

  function handleDragEnd({ source, destination }: DropResult) {
    if (!destination || source.index === destination.index) return
    questions.splice(destination.index, 0, questions.splice(source.index, 1)[0])
    if (onReorderQuestion) onReorderQuestion(source.index, destination.index)
  }

  function getScrollToQuestionError(): {
    key: string
    position: ScrollLogicalPosition
  } | null {
    const { error, questionIndex } = getFirstWebFormQuestionError(form)
    if (!error || !revision) return null
    const question = revision.questions[questionIndex]
    if (!question) return null
    return {
      key: question.uniqueKey,
      // Scroll to the area within the question where the error is displayed
      position: error.field.includes("richEditor")
        ? "start"
        : error.field.includes("options")
        ? "center"
        : "end",
    }
  }
}

export default observer(WebFormEditor)
